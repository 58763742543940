/* eslint-disable no-warning-comments */
import {AnimatePresence, domAnimation, LazyMotion, m} from 'framer-motion'
import * as React from 'react'

// import chiefgif from '@/assets/img/loader/chief_loop.gif'
// import chief from '@/assets/img/loader/chief_loop-webp.webp'
import Image from '@/components/Image'

export const PageTransitionWrapper = ({children, initial = true}) => (
  <LazyMotion features={domAnimation} strict>
    <AnimatePresence mode='wait' initial={initial}>
      {children}
    </AnimatePresence>
  </LazyMotion>
)

export const PageTransition = (pageProps) => {
  const isFirstRenderRef = React.useRef(true)

  React.useEffect(() => {
    isFirstRenderRef.current = false
  }, [])

  return (
    <PageTransitionWrapper>
      <div key={pageProps.path}>
        <m.div
          className={'pointer-events-none fixed left-0 top-0 z-50 h-screen w-screen bg-[#FFF2E5]'}
          initial={isFirstRenderRef.current ? false : {opacity: 1}}
          animate={{
            opacity: [0, 1, 1, 0],
          }}
          transition={{
            type: 'tween',
            duration: 2.4,
            times: [0, 0.3, 0.9, 1],
          }}
        >
          <div className='flex h-full w-full items-center justify-center'>
            <Image
              src={'https://a.storyblok.com/f/285541/1080x1080/4f1de1ebd0/loader_v2.gif'}
              alt={'gastronogeek loading gif loop'}
              className='lg:max-w-[630px h-auto w-full max-w-[390px] md:max-w-[530px]'
              sizes={{sm: 390, md: 530, unit: 'px'}}
              filters={{quality: 100}}
            />
          </div>
        </m.div>
      </div>
    </PageTransitionWrapper>
  )
}
