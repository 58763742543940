/* eslint-disable no-warning-comments */
import React, {createContext, useContext, useState} from 'react'

const AppContext = createContext()

export const AppContextProvider = ({children}) => {
  const [introPlayed, setIntroPlayed] = useState(false)
  // const [songPlaying, setSongPlaying] = useState(false)
  const [recetteOpen, setRecetteOpen] = useState(false)

  const setIntroPlayedTrue = () => {
    setIntroPlayed(true)
  }

  return (
    <AppContext.Provider
      value={{
        introPlayed,
        setIntroPlayedTrue,
        recetteOpen,
        setRecetteOpen,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error('useAppContext must be used within an AppContextProvider')
  }

  return context
}
