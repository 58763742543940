const theme = {
  colors: {
    grey: {
      800: '#282828',
      900: '#121212',
    },
    white: '#ffffff',
    black: '#000000',
    blue: {
      200: '#DBFFFF',
    },
    cream: '#FFF2E5',
    beige: {
      200: '#FFF2E5',
      300: '#EFE3D6',
    },
  },
}

module.exports = theme
