import * as React from 'react'
import {
  NODE_CODEBLOCK,
  NODE_HEADING,
  NODE_IMAGE,
  NODE_LI,
  NODE_OL,
  NODE_PARAGRAPH,
  NODE_QUOTE,
  NODE_UL,
  render,
} from 'storyblok-rich-text-react-renderer'
import resolveConfig from 'tailwindcss/resolveConfig'
import {UAParser} from 'ua-parser-js'
import urlJoin from 'url-join'

import {DEFAULT_LOCALE, LOCALES} from '@/common/config'

import rawTailwindConfig from '../../tailwind.config'

export const tailwindConfig = resolveConfig(rawTailwindConfig)

export const makeEditable = (_editable) => {
  if (!_editable) return {}

  const options = JSON.parse(_editable.replace(/^<!--#storyblok#/, '').replace(/-->$/, ''))

  return {
    'data-blok-c': JSON.stringify(options),
    'data-blok-uid': options.id + '-' + options.uid,
  }
}

export function isEmptyRichtext(richtext) {
  return !render(richtext)
}

export const unwrapAllNodeResolvers = {
  [NODE_HEADING]: (children) => <>{children}</>,
  [NODE_CODEBLOCK]: (children) => <>{children}</>,
  [NODE_IMAGE]: (children) => <>{children}</>,
  [NODE_PARAGRAPH]: (children) => <>{children}</>,
  [NODE_QUOTE]: (children) => <>{children}</>,
  [NODE_OL]: (children) => <>{children}</>,
  [NODE_UL]: (children) => <>{children}</>,
  [NODE_LI]: (children) => <>{children}</>,
}

export function storyToLink(story) {
  return {
    linktype: 'story',
    story: {
      full_slug: story.full_slug,
    },
  }
}

export function urljoinWithTrailing(...args) {
  return urlJoin(...args, '/')
}

export function sanitizeFullSlug(fullSlug = '') {
  return fullSlug.replace('index', '')
}

export function getUnlocalizedFullSlug(fullSlug = '') {
  return sanitizeFullSlug(fullSlug)
    .split('/')
    .filter((s, i) => i !== 0 || !LOCALES.includes(s))
    .join('/')
}

export function toKebabCase(string) {
  if (typeof string === 'string') {
    return string
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(/[\s_]+/g, '-')
      .toLowerCase()
      .replace(/[^a-z0-9-]/g, '')
  }

  return ''
}

export const formatDate = (date, locale) => {
  locale = locale || DEFAULT_LOCALE
  if (date) {
    return new Date(date).toLocaleDateString(locale, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })
  }
}

const originalSrcRegex = new RegExp(/\/\/a.storyblok.com/)

function isStoryblokImage(src) {
  return !!src && originalSrcRegex.test(src)
}

export const getDimensions = (src) => {
  if (isStoryblokImage(src)) {
    const match = src.match(/(\d+x\d+)/g)
    if (match && match[0]) {
      const [w, h] = match[0].split('x')
      return {width: parseInt(w), height: parseInt(h)}
    }
  }

  return undefined
}

export function isMobileOrTablet() {
  const ua = UAParser(navigator.userAgent)
  const {type} = ua.device
  return !!type && ['mobile', 'tablet'].includes(type)
}

// eslint-disable-next-line no-warning-comments
// eslint-disable-next-line no-unused-vars
export function freezeHeight(element, fn) {
  if (typeof window !== 'undefined' && element && isMobileOrTablet()) {
    const vh = window.screen.availHeight
    const height = fn ? fn(vh) : vh
    element.style.height = `${height}px`
  }
}
