/* eslint-disable no-warning-comments */
import classNames from 'classnames'
import * as React from 'react'
import useSound from 'use-sound'

import soundAmbiance from '@/assets/sounds/ambiance.wav'
// import {useAppContext} from '@/hooks/useAppContext'

const Sound = () => {
  const [play, {pause}] = useSound(soundAmbiance, {interrupt: false})

  const [showTooltip, setShowTooltip] = React.useState(false)
  const [songPlaying, setSongPlaying] = React.useState(false)
  // const {songPlaying, setSongPlaying, introPlayed} = useAppContext()

  React.useEffect(() => {
    setShowTooltip(true)
    setTimeout(() => {
      setShowTooltip(false)
    }, 5000)
  }, [])

  React.useEffect(() => {
    if (songPlaying === true) {
      play()
    } else {
      pause()
    }
  }, [play, pause, songPlaying])

  return (
    <button
      className={classNames(
        'fixed bottom-10 right-10 z-20 hidden h-10 w-10 items-center justify-center rounded-full bg-white lg:flex lg:h-14 lg:w-14',
        '[&:before]:absolute [&:before]:h-[20px] [&:before]:w-[20px] [&:before]:rounded-full [&:before]:bg-white [&:before]:opacity-0 [&:before]:transition-all [&:before]:duration-300 [&:before]:ease-in-out [&:before]:content-[""] [&:before]:hover:h-full [&:before]:hover:w-full [&:before]:hover:bg-beige-300 [&:before]:hover:opacity-100'
      )}
      onClick={() => setSongPlaying(!songPlaying)}
    >
      <div
        className={classNames(
          'tooltip shadow-[0px_0px_24px_0px_rgba(0,0,0,0.4] body-s absolute -left-47 top-1/2 max-w-[170px] -translate-y-1/2 rounded-xl bg-white px-4 py-2 transition duration-1000 ease-in-out',
          {
            'opacity-1 translate-x-0': showTooltip === true,
            'translate-x-6 opacity-0': showTooltip !== true,
          }
        )}
      >
        Pour une expérience optimale, activez le son
      </div>
      <div className='relative p-5'>
        {/* <svg
          width='56'
          height='56'
          viewBox='0 0 56 56'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className={classNames(
            songPlaying ? 'opacity-100' : 'opacity-0',
            'absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2 transition-all duration-200 ease-in-out'
          )}
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M19.25 29.1667V36.1667H15.75V29.1667H19.25Z'
            fill='#121212'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M26.25 19.8333L26.25 36.1666L22.75 36.1666L22.75 19.8333L26.25 19.8333Z'
            fill='#121212'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M33.25 24.5L33.25 36.1667L29.75 36.1667L29.75 24.5L33.25 24.5Z'
            fill='#121212'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M40.25 29.1667V36.1667H36.75V29.1667H40.25Z'
            fill='#121212'
          />
        </svg> */}

        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 640 512'
          className={classNames(
            songPlaying ? 'opacity-100' : 'opacity-0',
            'absolute left-1/2 top-1/2 w-5 -translate-x-1/2 -translate-y-1/2 transition-all duration-200 ease-in-out'
          )}
        >
          <path d='M151.6 188L288 66.7l0 378.6L151.6 324c-2.9-2.6-6.7-4-10.6-4l-85 0c-13.3 0-24-10.7-24-24l0-80c0-13.3 10.7-24 24-24l85 0c3.9 0 7.7-1.4 10.6-4zM290.2 32c-7.3 0-14.3 2.7-19.8 7.5L134.9 160 56 160c-30.9 0-56 25.1-56 56l0 80c0 30.9 25.1 56 56 56l78.9 0L270.4 472.5l10.6-12-10.6 12c5.5 4.8 12.5 7.5 19.8 7.5c16.5 0 29.8-13.3 29.8-29.8l0-388.4C320 45.3 306.7 32 290.2 32zM409.6 179.2c-7.1-5.3-17.1-3.9-22.4 3.2s-3.9 17.1 3.2 22.4C406 216.5 416 235.1 416 256s-10 39.5-25.6 51.2c-7.1 5.3-8.5 15.3-3.2 22.4s15.3 8.5 22.4 3.2C432.9 315.3 448 287.4 448 256s-15.1-59.3-38.4-76.8zm42-69.2c-5.6 6.8-4.7 16.9 2.1 22.5C489.4 161.9 512 206.3 512 256s-22.6 94.1-58.2 123.4c-6.8 5.6-7.8 15.7-2.1 22.5s15.7 7.8 22.5 2.1C516.8 368.9 544 315.6 544 256s-27.2-112.9-69.8-148.1c-6.8-5.6-16.9-4.7-22.5 2.1zm86.7-73.6c-6.7-5.7-16.8-4.9-22.6 1.8s-4.9 16.8 1.8 22.6c55.3 47 90.4 117 90.4 195.2s-35.1 148.2-90.4 195.2c-6.7 5.7-7.6 15.8-1.8 22.6s15.8 7.6 22.6 1.8C600.5 422.8 640 344 640 256s-39.5-166.8-101.6-219.6z' />
        </svg>

        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 640 512'
          className={classNames(
            'w-5',
            songPlaying ? 'opacity-0' : 'opacity-100',
            'absolute left-1/2 top-1/2 w-5 -translate-x-1/2 -translate-y-1/2 transition-all duration-200 ease-in-out'
          )}
        >
          <path d='M25.9 3.4C19-2 8.9-.8 3.4 6.1S-.8 23.1 6.1 28.6l608 480c6.9 5.5 17 4.3 22.5-2.6s4.3-17-2.6-22.5L25.9 3.4zM576 256c0-59.6-27.2-112.9-69.8-148.1c-6.8-5.6-16.9-4.7-22.5 2.1s-4.7 16.9 2.1 22.5C521.4 161.9 544 206.3 544 256c0 28.7-7.5 55.6-20.8 78.9l25.4 20C566 326 576 292.2 576 256zM472 294.4c5.2-11.8 8-24.8 8-38.4c0-31.4-15.1-59.3-38.4-76.8c-7.1-5.3-17.1-3.9-22.4 3.2s-3.9 17.1 3.2 22.4C438 216.5 448 235.1 448 256c0 6.1-.9 12-2.4 17.6L472 294.4zM352 61.8C352 45.3 338.7 32 322.2 32c-7.3 0-14.3 2.7-19.8 7.5l-72.1 64.1 25.5 20.1L320 66.7l0 107.8 32 25.3 0-137.9zM88 192l47.6 0L95.1 160 88 160c-30.9 0-56 25.1-56 56l0 80c0 30.9 25.1 56 56 56l78.9 0L302.4 472.5l10.6-12-10.6 12c5.5 4.8 12.5 7.5 19.8 7.5c16.5 0 29.8-13.3 29.8-29.8l0-87.4-32-25.3 0 107.8L183.6 324c-2.9-2.6-6.7-4-10.6-4l-85 0c-13.3 0-24-10.7-24-24l0-80c0-13.3 10.7-24 24-24z' />
        </svg>

        {/* <svg
          width='56'
          height='56'
          viewBox='0 0 56 56'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className={classNames(
            songPlaying ? 'opacity-0' : 'opacity-100',
            'absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2 transition-all duration-200 ease-in-out'
          )}
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M18.6667 32.6667V36.1667H15.1667V32.6667H18.6667Z'
            fill='#121212'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M25.6667 32.6667L25.6667 36.1667L22.1667 36.1667L22.1667 32.6667L25.6667 32.6667Z'
            fill='#121212'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M32.6667 32.6667L32.6667 36.1667L29.1667 36.1667L29.1667 32.6667L32.6667 32.6667Z'
            fill='#121212'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M39.6667 32.6667V36.1667H36.1667V32.6667H39.6667Z'
            fill='#121212'
          />
        </svg> */}
      </div>
    </button>
  )
}

export default Sound
