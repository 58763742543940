/* eslint-disable no-warning-comments */
/* eslint-disable react-hooks/rules-of-hooks */

import './src/styles/globals.scss'

import {gsap} from 'gsap'
import * as React from 'react'

import {loadScripts} from '@/common/scripts'
import {PageTransition, PageTransitionWrapper} from '@/components/PageTransition'
import Sound from '@/components/Sound'
import {AppContextProvider} from '@/hooks/useAppContext'

export const wrapRootElement = ({element}) => {
  return <AppContextProvider>{element}</AppContextProvider>
}

export const wrapPageElement = ({element, props}) => {
  React.useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    if (/windows phone/i.test(userAgent)) {
      document.documentElement.classList.add('windows')
    } else if (/android/i.test(userAgent)) {
      document.documentElement.classList.add('android')
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      document.documentElement.classList.add('ios')
    }
  }, [])

  React.useEffect(() => {
    loadScripts()
  }, [])

  return (
    <>
      <PageTransition {...props} />
      <PageTransitionWrapper initial={false}>{element}</PageTransitionWrapper>
      <Sound />
    </>
  )
}

export const onInitialClientRender = () => {
  const tl = gsap.timeline()
  tl.to('.navigation', {
    duration: 0.2,
    opacity: 1,
  })
  tl.to('.footer', {opacity: 1, duration: 0.2}, 0)
}
